.discover {
    text-align: center;
}

.title-discover {
    font-family: 'Tahoma';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #1E1C31;
}

.title-discover.pc {
    font-weight: 400;
}

.ant-divider {
    background-color: #5E5E5E;
    height: 100%;
}

.discover_info {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4%;
    justify-content: center;
    align-content: center;
}

.info_detail {
    justify-content:center;
    align-items: center;
}

.detail_numbers {
    font-weight: 600;
    font-size: 40px;
    line-height: 29px;
    color: #3F52FF;
    padding-top: 20px;
}

.detail_words {
    margin-top: 16px;
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    color: #787783;
}



@media only screen and (max-width: 600px) {
    .discover {
        margin-top: 20px;
    }    

    .title-discover {
        font-size: 20px;
        line-height: 32px;
    }

    .detail_numbers {
        font-size: 30px !important;
    }

    .detail_words {
        font-size: 18px !important;
        margin-top: 4px;
    }
    .info_detail {
        height: auto;
        
    }
    .hidden{
        display: none;
    }
    .discover_info {
        margin-top: 10px;
        gap: 20px;
        flex-direction: column;
    }

}