.CompanyPage{
    max-width: 926px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins';
    font-size: 22px;
    padding: 0 20px;
}
.CompanyPage h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #1E1C31;
    margin-top: 50px;
}
.CompanyPage h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    margin-left: 10px;
}
.wrapper-ul {
    margin-left: 50px;
    font-size: 20px;
}
.title-our {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 75px;
    line-height: 90px;
    color: #FFFFFF;
    text-align: center;
}
.subtitle-company {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
    .wrapper-ul {
        margin-left: 20px;
    }
    .CompanyPage{
       margin-left: 2% !important;
       margin-right: 2% !important;
       font-size: 16px;
       text-align: start;
    }
    .subtitle-company {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }
    .wrapper-ul {
        font-size: 16px;
        margin-left: 10px;
    }
}


