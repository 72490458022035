.homePage {
    text-align: center;
    width: 100%;
}
.block {
    margin-top: 120px;
}
.show-mb {
    display: none;
}
.show-pc {
    padding: 0 20px;
}
.namecp-hp {
    font-weight: 400;
    font-size: 70px;
    line-height: 76px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#slogan {
    font-family: Arial;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1E1C31;
}

#slogan2 {
    color: rgba(30, 28, 49, 0.6);
    max-width: 534px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 0;
    font-family: Arial;
}

.intro{
    position: relative;
    display: block;
}

.backgroundHome, .backgroundHome-mobile{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 30%;
}
@media only screen and (max-width: 900px) {
    .backgroundHome {
        display: none;
    }
    .backgroundHome-mobile {
        display: block;
    }
    h2 > .style-text {
        color: red;
    }
    .Company > p {
        font-size: 12px;
    }
}
@media only screen and (min-width: 900px) {
    .backgroundHome {
        display: flex !important;
    }
    .backgroundHome-mobile {
        display: none !important;
    }

}
.content h1 {
    font-weight: 700;
    font-size: 70px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 0;
}

.title-softfun {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #1E1C31;
}

.subtitle-sortfun {
    font-family: 'Poppins';
    font-style: normal;
}

.companyBG{
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin: 40px auto 0 auto;
}

.companyBG img{
    width: 100%;
    max-height: 100%;
    border-radius: 42px;
    object-fit: cover;
}

.companyBG:before{
    content: '';
    position:absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #3550AD 100%);
    opacity: 0.7;
    border-radius: 42px;
}

.btn-primary {
    position: absolute;
    left: 50%;
    bottom: 15%;
    transform: translateX(-50%);
    z-index: 10;
    cursor: pointer;
    width: 163px;
    height: 49px;
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    color: white;
    background-color: #4270EC;
    border: none;
    outline: none;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
}
@media only screen and (max-width: 1024px) {
    .content{
        width: 100%;
        position: relative !important;
        left: 0;
    }
    .backgroundHome{
        flex-direction: row-reverse;
        margin-top: 10px;
        gap: 0;
    }
}


@media only screen and (max-width: 700px) {
    .block {
        margin-top: 50px;
    }
    .show-mb {
        display: block;
    }
    /* .show-pc {
        display: none;
    } */
    h1{
        font-size: 26px !important;
        line-height: 30px !important;
    }
    h2{
        font-size: 32px;
    }
    #slogan {
        font-weight: 300;
        font-size: 12px;
        line-height: 22px;
        color: #1E1C31;
        text-transform: uppercase;
    } 
    .intro img{
        width: 150px;
        height: 260px;
    }
    .backgroundHome img{
        width: 100%;
        height: 400px;

    }
    .companyBG img{
        width: 100%;
        border-radius: 15px ;
        height: auto;
    }
    .companyBG:before{
        border-radius: 15px;
        width: 100%;
        left: 0;
    }

    .btn-primary {
        display: none;
    }
    
    .content{
        width: 100%;
        position: relative !important;
        left: 0;
    }
    .backgroundHome{
        flex-direction: row-reverse;
        margin-top: 10px;
        gap: -10px;
       
    }
    #slogan2{
        font-size: 15px;
        max-width: 250px;
    }
    .title-softfun {
        font-size: 26px;
        line-height: 30px;
    }
    .title-ourgame {
        font-size: 26px;
        line-height: 30px;
    }
    .title-client {
        font-size: 26px;
        line-height: 30px;
    }
}


/* react slick */
.slick-dots li {
    width: 4px;
    height: auto;
}

.slick-dots li button:before {
    width: 4px;
    color: #4270EB;
}

.slick-dots li.slick-active button:before {
    color: #4270EB;
}