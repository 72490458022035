.listGame{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 42px;
}

.title-ourgame {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #1E1C31;
}

.ourGame p{
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    font-family: 'Poppins';
    color: #1E1C31;
}
.nameGame{
    font-weight: 400 !important;
    font-size: 25px !important;
    color: black;
    margin-top: 20px;
    font-family: 'Poppins';
}
.card{
    transition: transform 0.5s;
}
.card:hover {
    transform: scale(1.1);
}
.card:hover > p{
    color: black;
    font-weight: 600 !important;
    font-family: 'Poppins';
}


@media screen and (max-width: 600px) {
    .card img{
        width: 150px;
    }
    .listGame{
        margin-top: 20px;
        gap: 30px;
        flex-wrap: nowrap;
        align-items: center;
        overflow: auto;
        justify-content: unset;
        padding: 0 20px;
        max-height: 240px;
        min-height: 232px;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .listGame::-webkit-scrollbar {
        display: none;
    }
    .ourGame p {
        font-size: 12px;
        line-height: 22px;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .listGame {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
    .nameGame{
        font-size: 16px !important;
    }
}