.AboutPage {
    text-align: center;
    font-family: 'Poppins';
}

.bgInfo{
    position: relative;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
}

.color-about {
    color: rgba(0, 71, 255, 1);
}

.bgInfo img{
    width: 100%;
    height: 542px;
    border-radius: 42px;
    object-fit: cover;
}

.mobileDisplayBg > h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 76px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0;
}

.bgContent{
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    z-index: 2;
    position: absolute;
    width: calc(100% - 40px);
}

.bgInfo::before{
    display: none;
    content: "";
    width: calc(100% - 40px);
    top: 80%;
    left: 20px;
    right: 0;
    bottom: 0;
    background: linear-gradient(185.04deg, rgba(0, 0, 0, 0) 30.99%, #6444E6 94.93%);
    border-radius: 42px;
    z-index: 1;
    position: absolute;
}

.bgContent h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 110px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3);
}

.bgContent p  {
    color: white;
    font-size: 18px;
}

.bgContent > p {
    max-width: 1081px;
    margin: 0 auto;
    padding: 0 10px;
}

.work{
    margin-top: 120px;
}
.philosophy > h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 75px;
    line-height: 90px;
    text-align: center;
    color: #1E1C31;
    max-width: 830px;
    margin: 0 auto;
}
.work h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
    color: #1E1C31;
}

.work p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    max-width: 926px;
    font-size: 25px;
    line-height: 30px;
    color: #1E1C31;
    margin: 30px auto 62px auto;
}

.imgWork{
    max-width: 1220px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33.33333333%;
    gap: 50px;
    row-gap: 30px;
    justify-content: center;

}
.imgWork img{
    width: 585px;
    transition: transform 0.5s;
    border-radius: 25px;
}


.mobileDisplayBg{
    display: none;
}

.philosophy{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.philosophy h2{
    text-align: center;
}

@media (min-width: 601px) and (max-width: 850px) {
    .bgContent p {
        font-size: 16px;
    }
    .bgContent h2 {
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 700px) {
    .bgInfo{
        margin-top: 20px;
    }
    .bgInfo img{
        height: auto;
        width: 100%;
        max-height: auto;
        border-radius: 10px;
        margin-top: 0;
    }
    .work {
        margin-top: 50px;
    }
    .work p {
        width: 100%;
        padding: 0 20px;
        margin-top: 10px;
        margin-bottom: 30px;
        font-size: 13px;
        line-height: 16px;
    }
    .work h3 {
        font-size: 28px;
        line-height: 36px;
        padding: 0 20px;
    }
    .bgInfo::before{
        width: 90%;
        left: 5%;
    }

    .imgWork img{
        width: 80%;
        height: auto;
    }
    .mobileDisplayBg{
        display: block;
    }
    .mobileDisplayBg p{
        margin-left: 7px;
        margin-right: 7px;
        font-size: 13px;
    }
    .philosophy {
        margin-top: 70px;
        padding: 0 20px;
    }
    .philosophy h2{
        font-size: 28px;
        line-height: 36px;
    }
    .bgInfo::before{
        border-radius: 10px;
    }
    .mobileHiddenContent{
        display: none;
    }
    .ant-image-mask:hover{
        display: none;
    }
}