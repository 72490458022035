.header {
    display: flex;
    justify-content: space-between;
    max-width: 1158px;
    margin: 0 auto;
}
.header li Link:active{
    color: red;
}
li > a {
    padding: 0 10px;
}
li > .active {
    background-color: #3F52FF;
}

.mobileHidden ul {
    float: right;
    list-style-type: none;
    display: flex;
    padding-top: 10px;
}

.mobileHidden ul li {
    padding-left: 45px;
}

.mobileHidden ul li a {
    font-weight: 600;
    font-size: 22px !important;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3F52FF;
}

.mobileHidden ul li  :hover{
    color: white;
}

.no-bg {
    background-color: transparent !important;
}

.mobileVisible{
    display: none;
}

.ant-drawer-body ul {
   list-style-type: none;
}
.ant-drawer-body ul li{
    padding-top: 10px;
}
.ant-drawer-body ul li a{
    font-size: 18px;
    color: black;
    
}

.ant-drawer button{
    background-color: transparent;
    width: auto !important;
    height: auto !important;
} 
.ant-drawer button:active{
    background-color: transparent;
} 

@media only screen and (max-width: 940px) {
    .mobileVisible {
        display: block;
    }

    .mobileVisible + img {
        display: block;
        width: 40px;
    }

    .menu-icon {
        width: 30px;
    }

    .mobileHidden {
        display: none;
    }
    .logo {
        padding: 0;
    }

    .logo-mobile {
        width: 64px;
    }
    .header {
        align-items: center;
    }
}
@media (min-width:940px) and (max-width: 1076px) {
    .mobileHidden ul li a{
        font-size: 18px !important;
    }
    .logo {
        padding-left: 20px;
    }
}