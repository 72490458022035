.GamePage {
    text-align: center;
    margin: 0 auto;
}

.subtitle-topgame {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.GamePage .backgroundGamePage {
    background-size: cover;
    width: 100%;
    margin-top: 100px;
}

.topGame {
    margin-top: 60px
}

.topGame h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #1E1C31;
}

.title-tg {
    margin-bottom: 100px;
}

.topGame p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #1E1C31;
}

.listToGame {
    max-width: 1283px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    row-gap: 65px;
    justify-content: center;
}

.cardGame {
    transition: transform 0.5s;
    position: relative;
}

.cardGame img{
    border-radius: 20px ;
}

.cardGame .gameInfo {
    position: absolute;
    z-index: 2;
    left: 18px;
    right: 18px;
    bottom: 35px;
}

.gameInfo h3 {
    color: white;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 17px;
}

.gameInfo p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
}

/* .cardGame::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 28.18%, #1D3982 91.99%);
    z-index: 1;
    border-radius: 20px;
} */
.sliderHidden {
    display: none;
}

.wrapper-discover {
    margin-top: 120px;
}

.cardGame:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 480px) {
    .title-tg {
        margin-bottom: 12px;
    }

    .cardGame:hover {
        transform: scale(1);
    }
    .topGame h2 {
        font-size: 26px;
        line-height: 30px;
    }
    .topGame p {
        max-width: 250px;
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;
    }
    .listToGame {
        display: none;
    }
    .sliderHidden {
        display: block;
    }
    .slick-list{
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .cardGame img{
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
    .gameInfo{
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    .cardGame::before{
        width: 90%;
        left: 5%;
    }
    .wrapper-discover {
        margin-top: 50px;
    }
    
}
@media (min-width:481px) and (max-width:1024px) {
    .listToGame{
        gap: 20px
    }
}
