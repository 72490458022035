.App{
  /* background-image: url('../public/images/Ellipse\ 14.png'),url('../public/images/Ellipse\ 15.png') ; */
  background-position: right center, left bottom;
  background-repeat:  no-repeat, no-repeat;
  background-size: 20%;
  background-origin: border-box;
  background: linear-gradient(180.07deg, #4200FF -63.07%, #FFFFFF 60.6%, #E8E6F5 99.94%);
  overflow: hidden;
}

.pd {
  padding: 20px;
}

@media screen and (max-width: 600px){
  .App{
    background-position: right top, left center;
    background-size: 180px;
  }
}
  
  