.Footer{
    color: #222136;
    max-width: 1158px;
    margin: 250px auto 0 auto;
    padding: 0 20px;
}

.Footer h5{
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    font-family: 'Poppins';
}
.Footer p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(34, 33, 54, 0.7);
    margin: 0;
}
.inputWithButton{
    position: relative;
    width: 100%;
    margin-bottom: 16px;
}

.inputWithButton input{
    background: #ECF0FC;
    border-radius: 9px;
    border-style: hidden;
    height: 40px;
    width: 100%;
    padding: 15px;
    padding-left: 5%;
}
.inputWithButton button{
    border-style: hidden;
    position: absolute;
    width: 30px;
    height: auto;
    background-color: transparent;
    border-radius: 0;
    margin-top: 1%;
    right:10px;
    top: 4.5px
}

.item-footer a {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #222136;
    text-decoration: underline;
}

.follow-footer {
    margin: 20px 0;
}

.inputWithButton button:hover{
    background-color: transparent;
}

.ant-col{
    width: 100%;
}

.Footer .Icon{
    width: 30px;
    height: 30px;
}

.Footer .logoBottom{
    width: 80%;
    max-width: 109px;
}

.hidden-pc {
    display: none;
}

@media only screen and (max-width: 940px) {
    .menu-bottom {
        display: flex;
        justify-content: space-between;
    }
    .Footer{
        color: #222136;
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        margin-top: 40px;
        text-align: center;
        justify-content: center;
        background-color: #ECECEC;
    }
    .Footer p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(34, 33, 54, 0.7);
    }
    .item-footer a {
        font-size: 14px;
        line-height: 22px;
    }
    .Footer .logoBottom{
        height: 43px;
        width: auto;
    }
    #pd-footer {
        padding: unset !important;
    }
    .ant-col{
        margin-top: 5px;
    }
    .hidden{
        display: none;
    }
    .Icon{
        height: 40px !important;
        width: 40px !important;
    }
    .inputWithButton{
        margin: auto;
    }
    .hidden-pc {
        display: block;
    }
    .out-contract {
        max-width: 320px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 940px) and (max-width: 991px) {
    .Footer {
        padding: 0 100px;
    }
    .inputWithButton {
        width: 50%;
    }
    .mb {
        margin-bottom: 30px;
    }
    .mb:last-child {
        margin-bottom: 0;
    }
    .follow-footer {
        margin: 10px 0;
    }
}