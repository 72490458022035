.client {
    max-width: 961px;
    margin: 0 auto;
}

.cover {
    position: relative;
    top: 0;
    left: 0;
}

.slider {
    position: relative;
}

.wrapper-img {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    padding-top: 100px;
    position: relative;
}


.contentCover p {
    max-width: 500px;
    margin: 0 auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}

.contentCover {
    position: relative;
}

.bg-slider {
    position: absolute;
    width: 100%;
}

.name-slider {
    display: block;
    margin: 20px 0 4px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #1E1C31;
}

.desc-slider {
    padding: 0 10px;
    word-wrap: break-word;
}

.job-slider {
    display: block;
    margin-bottom: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #1E1C31;
}

.wrapper-img .person {
    width: 117px;
    text-align: center;
}

.subtitle-client{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1E1C31;
}

.testimony-pc {
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -25%);
}

.testimony-mb {
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 40%);
}

.testimony-mb {
    display: none;
}

.title-client {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 61px;
    color: #1E1C31;
    max-width: 651px;
    margin: 0 auto;
}

.subtitle-client {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1E1C31;
}

.cover .slick-slider {
    justify-content: center;
    text-align: center;
    width: 60%;
    margin: auto;
}

.wrapper-img .bg-slidermb {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.cover img{
    width: 100%;
}

.PartnerText{
   width: 50%;
   margin: auto;
}
@media only screen and (max-width: 600px) {
    .contentCover p{
        font-size: 14px;
        max-width: 300px;
        margin-top: 30px;
    }

    .wrapper-img {
        margin-bottom: 20px;
    }

    .wrapper-img .person {
        width: 70px;
    }

    .cover .slick-slider{
        width: 100%;
    }
    .PartnerText{
        width: 90%;
    }

    .subtitle-client {
        font-size: 20px;
        line-height: 16px;
    }
    .testimony-pc {
        display: none !important;
    }
    .testimony-mb {
        display: block !important;
    }
    .title-client {
        padding: 0 20px;
    }
}
